import { apportionmentOption, ModalIDs, errorMessages, stateName } from "./common";
import React from 'react';

export const GetModalToShow = (modalName: string, index: number, showModal: any) => {
    switch (modalName) {
        case 'Council Rates': return showModal(ModalIDs.councilRates, { index })
        case 'Licence Fees': return showModal(ModalIDs.licenceFees, { index })
        case 'Sewerage Access Fee': return showModal(ModalIDs.sewerageAccessFee, { index })
        case 'Water Access Fee': return showModal(ModalIDs.waterAccessFee, { index })
        case 'Administration Fund': return showModal(ModalIDs.administrationFund, { index })
        case 'Sinking Fund': return showModal(ModalIDs.sinkingFund, { index })
        case 'Insurance': return showModal(ModalIDs.insurance, { index })
        case 'Strata Levies': return showModal(ModalIDs.strataLevies, { index })
        case 'Water Drainage Fee': return showModal(ModalIDs.waterDrainageFee, { index })
        case 'Parks Charge': return showModal(ModalIDs.parksCharge, { index })
        case 'Water Service Charge': return showModal(ModalIDs.waterServiceCharge, { index })
        case 'Sewerage Usage': return showModal(ModalIDs.sewerageUsage, { index })
        case 'Owners Corporation Fees': return showModal(ModalIDs.ownersCorporationFees, { index })
        case 'Maintenance Fund': return showModal(ModalIDs.maintenanceFund, { index })
        case 'Rent': return showModal(ModalIDs.rent, { index })
        case 'Council Rates, Charges, Levies': return showModal(ModalIDs.councilRatesChargesLevies, { index })
        case 'Water Rates, Charges & Levies': return showModal(ModalIDs.waterRatesChargesLevies, { index })
        case 'Sewerage Service Charge': return showModal(ModalIDs.sewerageServiceCharge, { index })
        case 'Owners Corporation - Administration Fund Fee': return showModal(ModalIDs.ownersAdministrationFundFee, { index })
        case 'Owners Corporation - Maintenance Fund Fee': return showModal(ModalIDs.ownersMaintenanceFundFee, { index })
        case 'Owners Corporation - Sinking Fund Fee': return showModal(ModalIDs.ownersSinkingFundFee, { index })
        case 'Owners Corporation - Insurance': return showModal(ModalIDs.ownersInsurance, { index })
        case 'Emergency Services Levy': return showModal(ModalIDs.emergencyServicesLevy, { index })
        case 'Water and Sewerage Rates': return showModal(ModalIDs.waterAndSewerageRates, { index })
        case 'Local Land Services': return showModal(ModalIDs.localLandServices, { index })
        case 'Water Availability': return showModal(ModalIDs.waterAvailability, { index })
        case 'Land Tax': return showModal(ModalIDs.landTax, { index })
        case 'Penalty Interest': return showModal(ModalIDs.penaltyInterest, { index })
        case 'Default Interest': return showModal(ModalIDs.defaultInterest, { index })
        case 'Water Usage': return showModal(ModalIDs.waterUsage, { index })
        case 'Recycled Water': return showModal(ModalIDs.recycledWater, { index })
        case 'Other Adjustment': return showModal(ModalIDs.otherAdjustment, { index })
        case 'Other Adjustment - Fixed Amount': return showModal(ModalIDs.otherAdjustment, { index })
        case 'Other Adjustment Date': return showModal(ModalIDs.otherAdjustmentDate, { index })
        case 'Other Adjustment - Date Period': return showModal(ModalIDs.otherAdjustmentDate, { index })
        case 'Water Rates': return showModal(ModalIDs.WaterRates, { index })
        case 'Sewerage Rates': return showModal(ModalIDs.SewerageRates, { index })
        case 'Drainage Charge': return showModal(ModalIDs.DrainageCharge, { index })
        case 'Release Fee': return showModal(ModalIDs.releaseFee, { index })
        case 'Discharge Fee': return showModal(ModalIDs.dischargeFee, { index })
        case 'SA Water Supply and Sewerage': return showModal(ModalIDs.SAWaterSupplyAndSewerage, { index })
        default: return null
    }
}

export const CalculateApportionmentValue = (info: any) => {
    {
        const amount = (showAdditionalFields(info["title"]) && info.value["method"] === "daily-average") ? info.value["averageDailyAmount"] : info.value["amount"];
        if (info.value["ctsOption"] == apportionmentOption.sharedPercentage && info.value["percentage"] != null)
            return (
                <>
                    Shared Percentage = {info.value["percentage"]}% = ${(amount * info.value["percentage"] / 100).toLocaleString("en-AU", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}
                </>
            )
        else if (info.value["ctsOption"] == apportionmentOption.entitlement && info.value["entitlementValue"] != null) {
            let entitlementVal = info.value["entitlementValue"].split("/")[0] / info.value["entitlementValue"].split("/")[1];
            return (
                <>
                    Unit/Lot Entitlement = {info.value["entitlementValue"]} = ${(amount * entitlementVal).toLocaleString("en-AU", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}
                </>)
        }
        else {
            return ""
        }
    }
}

export const reorderDragDropList = (list: { [key: string]: any }[] | null, startIndex: number, endIndex: number) : {[key: string] : any}[] => {
    const result = Array.from(list ?? []);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result.map((r, i) => ({ ...r, order: i }));
};

export const formatToLocaleDateString = (dateValue: Date | undefined) => {
    if (!dateValue)
        dateValue = new Date();

    return dateValue.toLocaleString("en-US", { day: 'numeric' }) + " " + dateValue.toLocaleDateString("en-US", { month: 'long' }) + " " + dateValue.toLocaleDateString("en-US", { year: 'numeric' });
}

export const formatToLocaleDateTimeString = (dateValue: Date | undefined) => {
    if (!dateValue)
        dateValue = new Date();

    let day = dateValue.toLocaleString("en-US", { day: 'numeric' });
    let month = dateValue.toLocaleString("en-US", { month: 'long' });
    let year = dateValue.toLocaleString("en-US", { year: 'numeric' });

    let localTime = dateValue.toLocaleString("en-US", { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
    return day + ' ' + month + ' ' + year + ' ' + localTime;
}

export const daysOfTheYear = (year: any) => {
    return isLeapYear(year) ? 366 : 365;
}

export const isLeapYear = (year: any) => {
    return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
}

export const renderSwitch = (modal: string | ModalIDs | null) => {

    switch (modal) {
        case ModalIDs.councilRates:
        case ModalIDs.licenceFees:
        case ModalIDs.administrationFund:
        case ModalIDs.sinkingFund:
        case ModalIDs.insurance:
        case ModalIDs.strataLevies:
        case ModalIDs.parksCharge:
        case ModalIDs.sewerageUsage:
        case ModalIDs.ownersCorporationFees:
        case ModalIDs.maintenanceFund:
        case ModalIDs.rent:
        case ModalIDs.councilRatesChargesLevies:
        case ModalIDs.sewerageServiceCharge:
        case ModalIDs.ownersAdministrationFundFee:
        case ModalIDs.ownersMaintenanceFundFee:
        case ModalIDs.ownersSinkingFundFee:
        case ModalIDs.ownersInsurance:
        case ModalIDs.emergencyServicesLevy:
        case ModalIDs.localLandServices:
        case ModalIDs.landTax:
        case ModalIDs.sewerageAccessFee:
        case ModalIDs.waterServiceCharge:
        case ModalIDs.waterRatesChargesLevies:
        case ModalIDs.waterDrainageFee:
        case ModalIDs.waterAvailability:
        case ModalIDs.waterAccessFee:
        case ModalIDs.waterAndSewerageRates:
            return true;
        default:
            return false;
    }
}

export const paymentStatus = (status: string) => {
    status = status.toLocaleLowerCase();
    return (status === 'paid' || status === 'plus' || status === 'adjust-as-paid') ? 'Plus' : (status === 'unpaid' || status === 'less') ? 'Less' : '';
}

export const escapeRegExp = (find: string) => {
    return find.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export const getAdjustmentPeriods = (period: string, settlementDate: Date) => {
    const datePeriod = {
        fromDate: new Date(),
        toDate: new Date()
    }

    const monthOfAdjustment: number = settlementDate.getMonth() + 1;
    switch (period) {
        case 'Daily':
            datePeriod.toDate = new Date(settlementDate);
            return datePeriod;
        case 'Weekly':
            return "";
        case 'Month':
            const totalDayInMonth: number = new Date(settlementDate.getFullYear(), monthOfAdjustment, 0).getDate();
            datePeriod.fromDate = new Date(monthOfAdjustment + "-01-" + settlementDate.getFullYear());
            datePeriod.toDate = new Date(monthOfAdjustment + "-" + totalDayInMonth + "-" + settlementDate.getFullYear());
            return datePeriod;
        case 'Quarter':
            if (monthOfAdjustment <= 3) {
                datePeriod.fromDate = new Date("01-01-" + settlementDate.getFullYear());
                datePeriod.toDate = new Date("03-31-" + settlementDate.getFullYear());
                return datePeriod;
            }
            else if (monthOfAdjustment > 3 && monthOfAdjustment <= 6) {
                datePeriod.fromDate = new Date("04-01-" + settlementDate.getFullYear());
                datePeriod.toDate = new Date("06-30-" + settlementDate.getFullYear());
                return datePeriod;
            }
            else if (monthOfAdjustment > 6 && monthOfAdjustment <= 9) {
                datePeriod.fromDate = new Date("07-01-" + settlementDate.getFullYear());
                datePeriod.toDate = new Date("09-30-" + settlementDate.getFullYear());
                return datePeriod;
            }
            else if (monthOfAdjustment > 9 && monthOfAdjustment <= 12) {
                datePeriod.fromDate = new Date("10-01-" + settlementDate.getFullYear());
                datePeriod.toDate = new Date("12-31-" + settlementDate.getFullYear());
                return datePeriod;
            }
            else {
                return settlementDate;
            }

        case 'HalfYearCalendar':
        case 'HalfYearFinancial':
            if (settlementDate.getMonth() + 1 > 6) {
                datePeriod.fromDate = new Date("07-01-" + settlementDate.getFullYear());
                datePeriod.toDate = new Date("12-31-" + settlementDate.getFullYear());
            }
            else {
                datePeriod.fromDate = new Date("01-01-" + settlementDate.getFullYear())
                datePeriod.toDate = new Date("06-30-" + settlementDate.getFullYear());
            }
            return datePeriod;

        case 'AnnualFinancial':
            if ((settlementDate.getMonth() + 1) <= 6) {
                datePeriod.fromDate = new Date("07-01-" + (settlementDate.getFullYear() - 1))
                datePeriod.toDate = new Date("06-30-" + settlementDate.getFullYear());
                return datePeriod;
            } else {
                datePeriod.fromDate = new Date("07-01-" + settlementDate.getFullYear())
                datePeriod.toDate = new Date("06-30-" + (settlementDate.getFullYear() + 1).toString());
                return datePeriod;
            }

        case 'AnnualCalendar':
            datePeriod.fromDate = new Date("01-01-" + settlementDate.getFullYear())
            datePeriod.toDate = new Date("12-31-" + settlementDate.getFullYear());
            return datePeriod;

        case '1/3Year':
            if (monthOfAdjustment <= 4) {
                datePeriod.fromDate = new Date("01-01-" + settlementDate.getFullYear());
                datePeriod.toDate = new Date("04-30-" + settlementDate.getFullYear());
            }
            else if (monthOfAdjustment > 4 && monthOfAdjustment <= 8) {
                datePeriod.fromDate = new Date("05-01-" + settlementDate.getFullYear());
                datePeriod.toDate = new Date("08-31-" + settlementDate.getFullYear());
            }
            else if (monthOfAdjustment > 8 && monthOfAdjustment <= 12) {
                datePeriod.fromDate = new Date("09-01-" + settlementDate.getFullYear());
                datePeriod.toDate = new Date("12-31-" + settlementDate.getFullYear());
            }
            return datePeriod;

        default:
            return settlementDate;
    }
}

export const billingPeriodOptions = () => {
    const billingPeriodOptions =
        [
            { key: 'Daily', text: 'Daily' },
            { key: 'Week', text: 'Week' },
            { key: 'Month', text: 'Month' },
            { key: 'Quarter', text: 'Quarter' },
            { key: '1/3Year', text: '1/3 Year' },
            { key: 'HalfYearFinancial', text: 'Half Year - Financial' },
            { key: 'HalfYearCalendar', text: 'Half Year - Calendar' },
            { key: 'AnnualFinancial', text: 'Annual - Financial' },
            { key: 'AnnualCalendar', text: 'Annual - Calendar' },
            { key: 'Other', text: 'Other' }
        ];
    return billingPeriodOptions;
}

export const getCTSOptions = () => {
    const ctsOptions =
        [
            { key: 'do-not-apportion', text: 'Do not Apportion' },
            { key: 'entitlement', text: 'Entitlement' },
            { key: 'shared-percentage', text: 'Shared Percentage' }
        ]
    return ctsOptions;
}

export const toFormatLocalString = (value: any, format: string, minFraction: number, maxFraction: number) => {

    if (typeof value === "number")
        return value.toLocaleString(format, { minimumFractionDigits: minFraction, maximumFractionDigits: maxFraction, })
    else {
        let numValue = parseFloat(value);
        return !Number.isNaN(numValue) ? numValue.toLocaleString(format, { minimumFractionDigits: minFraction, maximumFractionDigits: maxFraction, }) : (0.000).toFixed(maxFraction);
    }
}

export const showAdditionalFields = (adjustment: string) => {
    switch (adjustment) {
        case ModalIDs.waterAccessFee:
        case ModalIDs.sewerageAccessFee:
        case ModalIDs.waterAndSewerageRates:
        case ModalIDs.SewerageRates:
        case ModalIDs.WaterRates:
        case ModalIDs.SAWaterSupplyAndSewerage:
            return true;
        default:
            return false;
    }
}

// BIG NOTE:
// this function can be very problematic. the input data to this function when taking an end date subtracting by the start date, it does not consider the end date itself in the value
// and hence this function will return the number of days wihtout the end date, and cause the -1 date issue
// this should be fixed case by case basis
export const getNumberOfDays = (value: number): number => {
    return Math.round(value / 86400000);  // (1000 * 60 * 60 * 24)
}

export const roundToTwoDecimalPlaces = (value: number): number => {
    let result = Math.round(value * 100) / 100;
    return result;
}

export const convertStringToDate = (dateString: string): Date => {
    let date = new Date(dateString);
    const offset = date.getTimezoneOffset();

    date = new Date(date.getTime() - offset * 60000);

    date.setUTCHours(0);
    date.setUTCMinutes(0);
    date.setUTCSeconds(0);
    date.setUTCMilliseconds(0);

    return date;
}

export const getFundType = (key: string) => {
    switch (key) {
        case "Electronic": return "Electronic";
        case "BankCheque": return "Bank Cheque";
        case "TrustCheque": return "Trust Cheque";
        case "PersonalCheque": return "Personal Cheque";
        default: return "";
    }
}

export const getSourceOfFund = (fundType: string) => {
    switch (fundType) {
        case "TrustAccount": return "Trust Account";
        case "HomeLoanAtSettlement": return "Home Loan - At Settlement";
        case "OffsetAccount": return "Offset Account";
        case "SettlementFunds": return "Settlement Funds";
        case "SettlementShortfall": return "Settlement Shortfall";
        case "AdditionalFundNeeded": return "Additional Funds Needed for Settlement";
        case "BalanceOfDeposit": return "Balance of Deposit";
        case "DepositFunds": return "Deposit Funds";
        case "SaleSettlementProceeds": return "Sale Settlement Proceeds";
        case "FHOG": return "FHOG";
        default:
            return "";
    }
}

export const pdfTitle = (conveyType: string | undefined) => {
    switch (conveyType) {
        case "VendorStatement":
            return "Vendor Statement";
        case "PurchaserStatement":
            return "Purchaser Statement";
        case "AdjustmentStatement":
            return "Adjustment Statement";
        case "ClientSettlementStatement":
            return "Client Settlement Statement";
        default:
            return "Settlement Statement";
    }
}

export const validateModalData = (updatedState: any, modalID: ModalIDs): string => {
    if ((updatedState["from"] ? false : updatedState["to"] ? false : true) || (updatedState["to"] ? false : true)) {
        return errorMessages.dateInputError;
    }
    else if (updatedState["ctsOption"] === apportionmentOption.entitlement && (updatedState["entitlementValue"] ? false : true)) {
        return errorMessages.entitlementValueFormatError;
    }
    else if (updatedState["ctsOption"] === apportionmentOption.entitlement && updatedState["entitlementValue"].split('/').length == 1) {
        return errorMessages.entitlementValueFormatError;
    }
    else if (updatedState["ctsOption"] === apportionmentOption.sharedPercentage && (!!updatedState["percentage"] ? false : true)) {
        return errorMessages.sharedPercentageError;
    }

    else if (!showAdditionalFields(modalID!) && (updatedState["billingPeriod"] === "" || updatedState["adjustmentPeriod"] == "")) {
        return errorMessages.adjustmentBillingPeriodError;
    }

    else if (showAdditionalFields(modalID!) && updatedState["method"] === "billed-amount" && (updatedState["billingPeriod"] === "" || updatedState["adjustmentPeriod"] == "")) {
        return errorMessages.adjustmentBillingPeriodError;
    }
    else {
        return "";
    }
}

export const VendorSellerUtils = (state: string | undefined, statementType: string | undefined): string => {
	return state?.toLowerCase() === "qld" || statementType === "SettlementStatementWA" ? "Seller" : "Vendor";
}

export const PurchaserBuyerUtils = (statementType: string | undefined): string => {
    return statementType === "SettlementStatementWA" ? "Buyer": "Purchaser";
}

export const isQld = (state: string | undefined): boolean => {
    return state === stateName.QLD;
}

export const isWaStatement = (statement: string | undefined
): boolean => {
    return statement === "SettlementStatementWA";
}